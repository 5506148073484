<template>

	<div class="Customer_Information_form custom-modal-wrap">
		<el-dialog title="新增考勤人员" :visible.sync="shows" :close-on-click-modal="false"  :close-on-press-escape="false" @closed="onclosed">
			<div class="form_box">
				
				<div class="form_flex">
					<div class="lefts">
						姓名
					</div>
					<div class="rights">
						<!-- <input type="text" placeholder="请搜索选择" v-model="form.name"> -->
						<el-select v-model="bumen_select" placeholder="请选择">
							<el-option v-for="item in bumen_list" :key="item.value" :label="item.title" :value="item.value">
							</el-option>
						</el-select>
					</div>
				</div>
				
				<div class="form_flex">
					<div class="lefts">
						部门
					</div>
					<div class="rights">
						<!-- <input type="text" placeholder="请选择" v-model="form.phone"> -->
						<el-select v-model="bumen_select" placeholder="请选择">
							<el-option v-for="item in bumen_list" :key="item.value" :label="item.title" :value="item.value">
							</el-option>
						</el-select>
					</div>
				</div>
			
				<div class="form_flex">
					<div class="lefts">
						岗位
					</div>
					<div class="rights">
						<!-- <input type="text" placeholder="请选择" v-model="form.email"> -->
						<el-select v-model="position_select" placeholder="请选择">
							<el-option v-for="item in position_list" :key="item.value" :label="item.title" :value="item.value">
							</el-option>
						</el-select>
					</div>
				</div>
		
				<div class="form_flex">
					<div class="lefts">
						考勤类别
					</div>
					<div class="rights">
						<!-- <input type="text" placeholder="请选择" v-model="form.weixin"> -->
						<el-select v-model="kaoqin_select" placeholder="请选择">
							<el-option v-for="item in kaoqin_list" :key="item.value" :label="item.title" :value="item.value">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="btn_flex">
					<div class="btns2" @click="handle_ends" v-if="!form.id">提交</div>
					<div class="btns2" @click="handle_ends" v-if="form.id">提交</div>
					<div class="btns1" @click="handle_close">取消</div>
				</div>
			</div>
		</el-dialog>

	</div>
</template>
<script>
	export default {
		name: "Customer_Information_form",
		props: {

		},
		data() {
			return {
				title: 'Add customer information',
				shows: false,
				form: {
					id: 0,
					name: '', //门店名称
					phone: '', //手机号
					email: '', //邮箱
					weixin: '', //微信
				},


				//
				bumen_list: [{
						title: '类别1',
						value: '1'
					},
					{
						title: '类别2',
						value: '2'
					},
				],
				bumen_select: '',

				position_list: [{
						title: '类别1',
						value: '1'
					},
					{
						title: '类别2',
						value: '2'
					},
				],
				position_select: '',

				kaoqin_list: [{
						title: '类别1',
						value: '1'
					},
					{
						title: '类别2',
						value: '2'
					},
				],
				kaoqin_select: '',

			};
		},
		methods: {
			onclosed(){
				this.form= {
					id: 0,
					name: '', //门店名称
					phone: '', //手机号
					email: '', //邮箱
					weixin: '', //微信
				}
			},
			init() {
				this.shows = true
			},
			// 新增打开
			handle_add_open(row) {
				this.title = ''
				this.shows = true
			},
			//修改打开
			handle_modify_open(row) {
				this.title = ''
				let rows = JSON.parse(JSON.stringify(row))
				this.form = rows
				console.log('row', row)

				this.shows = true
			},
			//关闭    
			handle_close() {
				this.shows = false

			},
			//确认添加修改
			handle_ends() {
				if (!this.form.name) {
					alertErr('请选择用户')
					return
				}
				if (!this.form.name) {
					alertErr('请选择部门')
					return
				}
				if (!this.form.name) {
					alertErr('请选择岗位')
					return
				}
				if (!this.form.name) {
					alertErr('请选择考勤类别')
					return
				}

				this.loading = true
				this.$api("saveCarStore", {
					...this.form
				}, "post").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.shows = false
						if (this.form.id) {
							alertSucc()
						} else {
							alertSucc()
						}
						this.$emit("handle_get_list");

					} else {
						alertErr(res.msg)
					}
				});
			},
		},
		computed: {},

		watch: {
			shows(newval, oldval) {
				if (!this.shows) {
					this.form = {
						id: 0,
						name: '', //门店名称
						phone: '', //手机号
						email: '', //邮箱
						weixin: '', //微信
					}
				}
			}
		},


		created() {


		},


	};
</script>

<style scoped lang="less">
	.custom-modal-wrap {
		
		/deep/ .el-dialog .el-dialog__header {
			background: #fff !important;
		}
	}

	/deep/ .el-dialog__body {
		padding: 30px 50px 0 50px;
	}

	.btn_flex {
		padding: 25px 0;
		display: flex;
		justify-content: center !important;
	}


	.Customer_Information_form {
		/deep/.el-dialog {
			width: 680px;
		}

		/deep/.el-dialog__header {
			background: #F2F5FA !important;
		}

		.form_box {
			.form_flex {
				display: flex;
				align-items: center;
				margin-bottom: 16px;

				.lefts {
					width: 120px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 14px;
					color: #333333;

					&::before {
						content: '*';
						display: inline-block;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 16px;
						color: #FC5F03;
					}
				}

				.rights {
					// width: 446px;
					flex: 1;
					height: 40px;
					// background: #F2F5FA;
					border-radius: 4px 4px 4px 4px;

					input {
						padding: 0 12px;
						width: 100%;
						height: 40px;
					}

					/deep/.el-input {
						width: 100%;

							.el-input__inner {
							// background: #F2F5FA;
							// border: 0;
						}
					}

					.el-select {
						width: 100%;
					}
				}
			}

			.btn_flex {
				display: flex;
				align-items: center;
				justify-content: end;

				.btns1 {
					cursor: pointer;
					text-align: center;
					margin: 0 11px;
					width: 91px;
					height: 38px;
					line-height: 38px;
					background: #FFFFFF;
					border-radius: 4px 4px 4px 4px;
					border: 1px solid #B8B8B8;

					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 16px;
					color: #6A6A6A;
				}

				.btns2 {
					cursor: pointer;
					text-align: center;
					margin: 0 11px;

					width: 91px;
					height: 38px;
					line-height: 38px;
					background: #2373C8;
					border-radius: 4px 4px 4px 4px;

					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;
				}
			}
		}

	}
</style>